import { when } from 'mobx'
import { getRoot, onPatch, types } from 'mobx-state-tree'

import * as Analytics from '../utils/analytics'
import {
  getBoundingBox,
  maybeFilterByLanguage,
  maybeFilterByPartnerTags,
  maybeFilterBySpecialty,
} from '../utils/helpers'
import { LocationModel } from './LocationModel'
import { LOCATION } from './queries'

export const LocationStore = types
  .model('LocationStore', {
    focusLocation: types.maybeNull(types.safeReference(LocationModel)),
  })
  .views(self => ({
    get store() {
      return getRoot(self)
    },
    get locationsSortedByRank() {
      return Array.from(self.store.locations.values()).sort((a, b) => {
        // Compare providers with highest rank
        if (a.highestProviderRank < b.highestProviderRank) {
          return -1
        }
        if (a.highestProviderRank > b.highestProviderRank) {
          return 1
        }
        return 0
      })
    },
    get locations() {
      const specialtyFilteredLocations = maybeFilterBySpecialty(
        self.locationsSortedByRank,
        self.store.searchStore.filteredSpecialty
      )
      const partnerTagFilteredLocations = maybeFilterByPartnerTags(
        self.locationsSortedByRank,
        self.store.searchStore.filteredPartnerTags
      )
      const languageFilteredLocations = maybeFilterByLanguage(
        self.locationsSortedByRank,
        self.store.searchStore.filteredLanguage
      )
      if (
        self.store.searchStore.filteredSpecialty === '' && // OFF
        self.store.searchStore.filteredPartnerTags.length > 0 && // ON
        self.store.searchStore.filteredLanguage.length > 0 // ON
      ) {
        // Union of partner tags and language filters
        const filteredLocations = new Set(
          partnerTagFilteredLocations.concat(languageFilteredLocations)
        )
        return Array.from(filteredLocations.values())
      } else if (
        self.store.searchStore.filteredSpecialty !== '' && // ON
        self.store.searchStore.filteredPartnerTags.length > 0 && // ON
        self.store.searchStore.filteredLanguage.length > 0 // ON
      ) {
        // Union of specialty, partner tags, and language filters
        const filteredLocations = new Set(
          specialtyFilteredLocations.concat(
            partnerTagFilteredLocations,
            languageFilteredLocations
          )
        )
        return Array.from(filteredLocations.values())
      } else if (
        self.store.searchStore.filteredSpecialty === '' && // OFF
        self.store.searchStore.filteredPartnerTags.length > 0 && // ON
        self.store.searchStore.filteredLanguage.length === 0 // OFF
      ) {
        return partnerTagFilteredLocations
      } else if (
        self.store.searchStore.filteredSpecialty === '' && // OFF
        self.store.searchStore.filteredPartnerTags.length === 0 && // OFF
        self.store.searchStore.filteredLanguage.length > 0 // ON
      ) {
        return languageFilteredLocations
      } else if (
        self.store.searchStore.filteredSpecialty !== '' && // ON
        self.store.searchStore.filteredPartnerTags.length > 0 && // ON
        self.store.searchStore.filteredLanguage.length === 0 // OFF
      ) {
        const partnerTagFilteredLocationsSet = new Set(
          partnerTagFilteredLocations
        )
        // Intersection of filterSpecialty and filterPartnerTags
        const filteredLocations = specialtyFilteredLocations.filter(location =>
          partnerTagFilteredLocationsSet.has(location)
        )
        return filteredLocations
      } else if (
        self.store.searchStore.filteredSpecialty !== '' && // ON
        self.store.searchStore.filteredPartnerTags.length === 0 && // OFF
        self.store.searchStore.filteredLanguage.length > 0 // ON
      ) {
        const languageFilteredLocationsSet = new Set(languageFilteredLocations)
        // Intersection of filterSpecialty and filterPartnerTags
        const filteredLocations = specialtyFilteredLocations.filter(location =>
          languageFilteredLocationsSet.has(location)
        )
        return filteredLocations
      } else {
        return specialtyFilteredLocations
      }
    },
    get hasLocations() {
      return self.locations.length > 0
    },
  }))
  .actions(self => ({
    afterCreate() {
      onPatch(self, patch => {
        when(
          () => patch.path === '/focusLocation' && patch.value,
          () => {
            const coordinates = getBoundingBox(
              self.focusLocation.latNumber,
              self.focusLocation.lngNumber,
              15
            )
            self.store.setMapCenter(coordinates)
          }
        )
      })
    },
    setFocusLocation(location) {
      Analytics.focusLocation(location.id, self.store.partnerId)
      self.focusLocation = location
    },
    resetFocusLocation() {
      self.focusLocation = null
    },
    maybeFetchLocation(locationId) {
      if (self.store.locations.has(locationId)) {
        self.store.debug(`NOT LOADING LOCATION WITH ID ${locationId}`)
      } else {
        self.store.debug(`LOADING LOCATION WITH ID ${locationId}`)
        self.store.query(LOCATION, { id: locationId })
      }
    },
  }))
