import qs from 'qs'

import { logger } from './logger'

export const isDev = process.env.NODE_ENV === 'development'
export const isTest = process.env.NODE_ENV === 'test'
export const isReactotron = process.env.REACT_APP_ENABLE_REACTOTRON === 'true'
export const isDebugMode =
  qs.parse(window.location.search) &&
  qs.parse(window.location.search, { ignoreQueryPrefix: true }).debug === 'true'

export const radiusToZoom = distance => {
  logger.debug(distance)
  if (distance < 1) {
    return 4
  }
  return Math.round(14 - Math.log(distance) / Math.LN2)
}

export const phoneNumberMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const normalizePhoneNumber = phoneNumber => {
  if (typeof phoneNumber !== 'string') {
    logger.error(phoneNumber)
    return ''
  }
  const normalizedPhoneNumber = phoneNumber
    .replace(/\D/g, '')
    .replace(/^0+/, '')
  return normalizedPhoneNumber.length > 10
    ? normalizedPhoneNumber.substring(normalizedPhoneNumber.length - 10)
    : normalizedPhoneNumber
}

export const normalizedNumberMask = [
  /[1-9]/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

export const byName = (left, right) => {
  const leftName = left.name.toUpperCase()
  const rightName = right.name.toUpperCase()
  if (leftName < rightName) {
    return -1
  }
  if (leftName > rightName) {
    return 1
  }
  return 0
}

export function getBoundingBox(latitude, longitude, zoomLevel) {
  // Constants
  const tileSize = 256 // Google Maps tile size
  const earthRadius = 6378137 // Earth's radius in meters

  // Calculate the number of pixels at the given zoom level
  const pixelCount = 256 * Math.pow(2, zoomLevel)

  // Calculate the distance represented by one pixel in meters
  const metersPerPixel = (2 * Math.PI * earthRadius) / pixelCount

  // Calculate the bounding box half size in meters
  const halfWidth = (metersPerPixel * tileSize) / 2
  const halfHeight = (metersPerPixel * tileSize) / 2

  // Calculate the bounding box coordinates
  const south = latitude - (180 / Math.PI) * (halfHeight / earthRadius)
  const north = latitude + (180 / Math.PI) * (halfHeight / earthRadius)
  const west = longitude - (180 / Math.PI) * (halfWidth / earthRadius)
  const east = longitude + (180 / Math.PI) * (halfWidth / earthRadius)

  return { south, west, north, east }
}

export const maybeFilterBySpecialty = (
  locations = [],
  filteredSpecialties = ''
) => {
  if (filteredSpecialties === '') {
    return locations
  }
  return locations.filter(location => {
    if (location.specialties !== '') {
      return location.specialties.includes(filteredSpecialties)
    }
    return false
  })
}

export const maybeFilterByPartnerTags = (
  locations = [],
  filteredPartnerTags = []
) => {
  if (filteredPartnerTags.length > 0) {
    return locations.filter(location => {
      if (location.partnerTags && location.partnerTags.length > 0) {
        return (
          location.partnerTags.find(partnerTag => {
            return filteredPartnerTags.indexOf(partnerTag.name) >= 0
          }) !== undefined
        )
      }
      return false
    })
  }
  return locations
}

export const maybeFilterByLanguage = (locations = [], filteredLanguages) => {
  if (filteredLanguages.length > 0) {
    return locations.filter(location => {
      if (
        !filteredLanguages.includes('English') &&
        !filteredLanguages.includes('French') &&
        !filteredLanguages.includes('Spanish')
      ) {
        const regex = new RegExp(/\b(?!English|French|Spanish\b)\w+/, 'i')

        return regex.test(location.formattedLanguagesSpoken)
      } else {
        return (
          location.languagesSpoken.find(languageSpoken => {
            return filteredLanguages.indexOf(languageSpoken) >= 0
          }) !== undefined
        )
      }
    })
  }
  return locations
}

export const boxFromCoordinates = (coordinates = []) => {
  const north = Math.max(...coordinates.map(c => c.lat))
  const south = Math.min(...coordinates.map(c => c.lat))
  const east = Math.max(...coordinates.map(c => c.lng))
  const west = Math.min(...coordinates.map(c => c.lng))
  return { north, south, east, west }
}

export const DISTANCES = (language = 'en') => {
  const unit = language === 'en' ? 'miles' : 'kilometers'

  if (unit === 'miles') {
    return [
      { value: 5, text: `5 ${unit}` },
      { value: 10, text: `10 ${unit}` },
      { value: 15, text: `15 ${unit}` },
      { value: 25, text: `25 ${unit}` },
      { value: 50, text: `50 ${unit}` },
      { value: 100, text: `100 ${unit}` },
      { value: 250, text: `250 ${unit}` },
    ]
  }
  return [
    { value: 6, text: `10 ${unit}` },
    { value: 15, text: `25 ${unit}` },
    { value: 25, text: `40 ${unit}` },
    { value: 62, text: `100 ${unit}` },
    { value: 93, text: `150 ${unit}` },
    { value: 155, text: `250 ${unit}` },
    { value: 310, text: `500 ${unit}` },
  ]
}
