import { conformToMask } from 'react-text-mask'

import {
  isDebugMode,
  normalizePhoneNumber,
  phoneNumberMask,
} from '../utils/helpers'
import { LocationModelBase } from './LocationModel.base'

/* A graphql query fragment builders for LocationModel */
export {
  selectFromLocation,
  locationModelPrimitives,
  LocationModelSelector,
} from './LocationModel.base'

/**
 * LocationModel
 *
 * A Location
 */
export const LocationModel = LocationModelBase.views(self => ({
  get formattedPhoneNumber() {
    const normalizedPhoneNumber = normalizePhoneNumber(self.phoneNumber)
    return conformToMask(normalizedPhoneNumber, phoneNumberMask, {
      guide: false,
    }).conformedValue
  },
  get formattedAddress() {
    return `${self.street}, ${self.city} ${self.state}, ${self.zip}`
  },
  get directionsUrl() {
    return `http://google.com/maps/dir/?api=1&destination=${self.street}+${self.city}+${self.state}+${self.zip}`
  },
  get practiceName() {
    return self.displayName
  },
  get practiceId() {
    return self.practice.id
  },
  get latNumber() {
    return Number(self.lat)
  },
  get lngNumber() {
    return Number(self.lng)
  },
  get specialties() {
    if (self.providers) {
      return self.providers.map(provider => provider.specialtyList).join(', ')
    }
    return ''
  },
  get formattedLanguagesSpoken() {
    if (self.languagesSpoken) {
      return self.languagesSpoken.join(', ')
    }
    return ''
  },
  get highestProviderRank() {
    if (self.providers) {
      const providerRanks = self.providers.map(provider => provider.rank).sort()

      if (providerRanks[0]) {
        return providerRanks[0]
      }
      /**
       * Artificial rank to push unranked providers down in the sort list
       */
    }
    return 1000
  },
  get bookingPath() {
    if (self.dolphinBookingUrl) {
      return 'dolphin'
    } else {
      return 'request'
    }
  },
  /**
   * Provides a fully qualified dolphin booking url with all necessary url params
   *
   * @returns string A Url fully qualified url with partner_id param if partner is present
   */
  get fullDolphinBookingUrl() {
    let bookingUrl = self.dolphinBookingUrl
    if (self.store.partner) {
      bookingUrl += `&${self.store.partner.id}`
    }
    if (isDebugMode) {
      bookingUrl += '&debug=true'
    }
    return bookingUrl
  },
}))
